import { Component as ReactComponent } from 'react';
import type { ComponentType, ReactNode } from 'react';

import displayName from '../components-search-controls-adjacent/display-name';
import { BackendGatewayShape as LegacyBackendGatewayShape } from '../shapes';

import type { BackendGatewayShape } from '../types';
import type { Optional } from '@skyscanner-internal/falcon-shared-types/types/components';

type Props = {
  value: BackendGatewayShape;
  children: ReactNode;
};

type ContextProps = {
  backendGateway: BackendGatewayShape;
};

const ContextTypes = {
  backendGateway: LegacyBackendGatewayShape,
};

export class BackendContextProvider extends ReactComponent<Props> {
  static childContextTypes = ContextTypes;

  getChildContext() {
    const { value } = this.props;
    return {
      backendGateway: value,
    };
  }

  render() {
    return <> {this.props.children} </>;
  }
}

export const withBackendContext = <P extends ContextProps>(
  Component: ComponentType<P>,
): ComponentType<Optional<P, 'backendGateway'>> => {
  const component = (
    props: Optional<P, 'backendGateway'>,
    { backendGateway }: ContextProps,
  ) => (
    // @ts-ignore we expect that backendGateway can be overwritten in the unit tests
    <Component backendGateway={backendGateway} {...(props as P)} />
  );

  component.contextTypes = ContextTypes;

  component.displayName = displayName(Component);

  return component;
};
