import { wrapDate } from '../../hotels-utils/date-utils';

import type { SearchResultShape } from '../../hotels-utils/types';
import type { Stay } from '@skyscanner-internal/falcon-shared-types/types/HotelStay';

export const parseStayProp = (stay: Stay): Stay => ({
  ...stay,
  checkIn: wrapDate(stay.checkIn),
  checkOut: wrapDate(stay.checkOut),
});

export const isEmptySearchResults = (
  hotelSearchResults: SearchResultShape = {},
) => {
  const { hotelCards = [], loading = true } = hotelSearchResults;

  return !loading && hotelCards.length === 0;
};
