import formatDistance from './format-distance';
import LocalisationService from './localisation-service';
import TranslationService from './translation-service';

import type { Culture } from '@skyscanner-internal/falcon-shared-types/types/Context';
import type { I18n } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/types';

const I18nService = (
  {
    cldr,
    culture,
    translations,
  }: {
    cldr: Record<string, any>;
    culture: Culture;
    translations: Record<string, any>;
  },
  logger?: { error: Function },
): I18n => {
  const translationService = TranslationService(translations);
  const localisationService = LocalisationService(cldr, culture, logger);
  return {
    ...translationService,
    ...localisationService,
    formatDistance: (distanceMeters: number, options?: {}, extra?: {}) =>
      formatDistance(
        translationService,
        localisationService,
        culture.market,
        distanceMeters,
        options,
        extra,
      ),
    culture,
    translations: () => translations,
    cldr: () => cldr,
  };
};

export default I18nService;
